<template>
    <div class="table-responsive table-striped my-1" style="min-height: 200px">
        <table class="table">
            <thead class="table-light">
                <tr>
                    <th>SL No</th>
                    <th>Supplier Name</th>
                    <th>Mobile</th>
                    <th class="text-right">Opening Balance</th>
                    <th class="text-right">Debit</th>
                    <th class="text-right">Credit</th>
                    <th class="text-right">Closing Balance</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item ,index) in tableData" :key="index">
                    <td>
                        {{ index+1 }}
                    </td>
                    <td>{{item.name}}</td>
                    <td>{{item.phone}}</td>
                    <td class="text-right">{{ commaFormat(item.opening_balance) }}</td>
                    <td class="text-right">{{ commaFormat(item.debit) }}</td>
                    <td class="text-right">{{ commaFormat(item.credit) }}</td>
                    <td class="text-right">{{ commaFormat(item.closing_balance) }}</td>

                </tr>
                <tr class="bg-blue-light">
                  <td></td>
                  <td></td>
                  <td><strong>TOTAL</strong></td>
                  <td class="text-end">{{ commaFormat(getTotal('opening_balance')) }}</td>
                  <td class="text-end">{{ commaFormat(getTotal('debit')) }}</td>
                  <td class="text-end">{{ commaFormat(getTotal('credit')) }}</td>
                  <td class="text-end">{{ commaFormat(getTotal('closing_balance')) }}</td>
                </tr>

            </tbody>
        </table>
        <p v-if="!tableData.length" class="mt-5 text-center">No data</p>
    </div>
</template>

<script>
import figureFormatter from '@/services/utils/figureFormatter'

export default {
    name: 'DaybookTable',
    props: {
        tableData: {
            type: Array
        }
    },
    setup(props) {
        const {commaFormat} = figureFormatter ()

        const getTotal = (key) => {
          return props.tableData.reduce((sum, item) => sum + Number(item[key]), 0)
        }

        return {
            commaFormat,
            getTotal
        }
    },

}
</script>
